exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artikler-js": () => import("./../../../src/pages/artikler.js" /* webpackChunkName: "component---src-pages-artikler-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-maskiner-og-utstyr-js": () => import("./../../../src/pages/maskiner-og-utstyr.js" /* webpackChunkName: "component---src-pages-maskiner-og-utstyr-js" */),
  "component---src-pages-om-oss-js": () => import("./../../../src/pages/om-oss.js" /* webpackChunkName: "component---src-pages-om-oss-js" */),
  "component---src-pages-personvern-js": () => import("./../../../src/pages/personvern.js" /* webpackChunkName: "component---src-pages-personvern-js" */),
  "component---src-pages-produkter-js": () => import("./../../../src/pages/produkter.js" /* webpackChunkName: "component---src-pages-produkter-js" */),
  "component---src-pages-prosjekter-js": () => import("./../../../src/pages/prosjekter.js" /* webpackChunkName: "component---src-pages-prosjekter-js" */),
  "component---src-templates-archive-js": () => import("./../../../src/templates/archive.js" /* webpackChunkName: "component---src-templates-archive-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

